/*------------- Variable ------------*/

:root {
  --iq-title-text: #ffffff;
  --iq-body-text: #D1D0CF;
  --iq-body-bg : #141414;
  --iq-primary: rgba(72, 55, 255);
  --iq-secondary:#83878a;
  --iq-success: #14e788;
  --iq-warning: #f68a04;
  --iq-danger:#ff0000;
  --iq-info: #007aff;
  --iq-dark: #000000;
  --iq-light: #fafafb;
  --iq-white: #ffffff;
  --iq-black: #ffffff;
  --iq-gray: #b1b1b1;
  --iq-dark1:#a2a4af;
  --iq-light-primary: rgba(226,14,2,0.2);
  --iq-light-secondary: rgba(131,135,138,0.2);
  --iq-light-success: rgba(20,231,136,0.2);
  --iq-light-danger:rgba(84,94,117,0.2);
  --iq-light-warning:rgba(246,138,4,0.2);
  --iq-light-info: rgba(0,122,255,0.2);
  --iq-light-light:rgba(250,250,251,0.2);
  --iq-light-dark: rgba(0,0,0,0.2);
  --iq-primary-hover: #3300bf;
   --iq-secondary-hover: #807e7e;
  --iq-success-hover: #42f9d9;
  --iq-danger-hover: #3300bf;
  --iq-warning-hover: #fdb45e;
  --iq-info-hover: #c280ea;
  --iq-dark-hover: #44454e;
  --iq-light-hover: #eaeeff;
  --iq-border: #141414;
  --iq-border-light: #141414;
  --iq-border-danger: #ff9273;
  --iq-light-card:#191919;
  --iq-primary-rgb: 100, 114, 248;
  --iq-primary-light: #f3fffe;
  --iq-bg1:#363636;
  --iq-disable:#313946;
}
