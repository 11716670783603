.navbar-list li{
    float: left;
    list-style-type: none;
    position: relative;
}
.show .iq-sub-dropdown{
    display: inline-block !important;
}
.iq-color{
    color: white !important;
}
/* .badge{
    line-height: 1.5 !important;
    font-size: 14px;
    width: 10%
} */
.iq-text{color:#d1d0cf!important}
.css-319lph-ValueContainer .css-1s2u09g-control .css-tlfecz-indicatorContainer{
    background: #141414 !important;
    border: none !important
}
.css-1okebmr-indicatorSeparator{
    background: #141414 !important;
    border: none !important
}
.css-qc6sy-singleValue{
    color: #D1D0CF !important;
    border: none !important
}
.css-1s2u09g-control .css-b62m3t-container{
    background-color: #141414 !important;
    border: none!important
}
.css-1pahdxg-control{
    background-color: #141414 !important;
    border: none!important;
    box-shadow: none !important
}
.css-1f43avz-a11yText-A11yText {
    background-color: #141414 !important;
    border: none!important
}
.css-b62m3t-container{
    background-color: #141414 !important;
    border: none!important;
    box-shadow: none !important
}
.css-1s2u09g-control{
    background-color: #141414 !important;
    border: none!important;
}
.css-2613qy-menu{
    background-color: #141414 !important;
}

.css-tlfecz-indicatorContainer{
    background: #141414 !important
}

.css-yk16xz-control{
    background: #141414 !important;
  
} 
.css-1gtu0rj-indicatorContainer{
    background: #141414 !important;
}
.iq-select.css-b62m3t-container #react-select-2-listbox{
    background-color: var(--iq-body-bg) ;
    color: var(--iq-white);
}
.iq-select.css-b62m3t-container #react-select-2-listbox{
    /* background-color: var(--iq-primary); */
}

